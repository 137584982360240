/* Global Styles */
:root {
    --primary-color: #126262; /* #956963; */
    --medium-primary-color: #418180;
    --light-primary-color: #a2bbb4; /* #bd9889 */
    --very-light-primary-color: #f0fbf8;

    --secondary-color: #125780;
    --medium-secondary-color: #2578a8;
    --light-secondary-color: #c1def1;
    --very-light-secondary-color: #f5feff;

    --bg-color: #fafafa; /* fdfaf8 faf2f2 #eff5f2  f2f5ef f6f3f3 E6D9D6 f9fbfb f3f5f5 */

    --light-warm-color: #f8f1e9;
    --very-light-warm-color: #fcf7f3; /*f8f1eb*/
    --light-cool-color: #e4eaee;
    --very-light-cool-color: #f1f7f9; /* #f4f0ee; */

    --light-color: #c4e2d6; /* #dbc0b5 */
    --very-light-color: #eef8f5;

    --dark-color: #56546b; /* #5d3a3a */
    --darker-color: #343241; /* #5d3a3a */
    --muted-color: #7b7a8a; /* #746565 */
    --disabled-color: #908fa0; /* #746565 */

    --highlight-color: #0b3954; /* 5d7138 6fa79b df9e44 */
    --medium-highlight-color: #125780;
    --bright-highlight-color: #1f9be2;

    --catchy-color: #886118;
    --light-catchy-color: #b1a795;

    --gray-accent-color: #e2e0e2;
    --light-gray-accent-color: #f3eff3;
    --very-light-gray-accent-color: #f8f5f8;

    --button-font-color: #0e4949;

    --danger-color: #bb607d; /* b1677f c1666b  c06a5d B26E63 */
    --very-light-danger-color: #fff9fb;
    --warning-color: #958736; /* b1677f c1666b  c06a5d B26E63 */
    --very-light-warning-color: #fffdf6;
    --success-color: #567539;
    --very-light-success-color: #fafff5;

    --default-clickable-tag-color: #305970;

    --font-color: var(--dark-color); /* rgb(93, 79, 79) */
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

html,
body {
    width: 100%;
    height: 100%;
}

body {
    font-family: 'Montserrat', sans-serif; /* Open Sans */
    font-size: 1rem;
    background-color: var(--bg-color);
    color: var(--font-color);
}

#root {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

section {
    margin: 0;
}

.flex {
    display: flex;
}

a {
    color: var(--primary-color);
    text-decoration: none;
    font-weight: bold;
}

h1,
h2,
h3 {
    hyphens: auto;
}

.normalweight {
    font-weight: normal;
}

h1 {
    font-family: 'Bebas Neue', sans-serif;
    font-size: 2.3rem;
    letter-spacing: 2px;
}

h1.title {
    font-family: 'Pacifico', sans-serif;
    font-weight: 400;
    color: var(--highlight-color);
    margin-bottom: 0.15rem;
    letter-spacing: 0 !important;
}

h1 a {
    letter-spacing: 0;
}

h2 {
    font-size: 1.5rem;
    line-height: 1.6rem;
    margin-bottom: 1rem;
    font-weight: 700;
    color: var(--dark-color);
}

.header-with-button {
    display: grid;
    grid-template-columns: 1fr auto;
}

.intro-tagline {
    color: var(--catchy-color);
    letter-spacing: 0.13rem;
}

ul {
    list-style: none;
}

img {
    width: 100%;
    object-fit: cover;
}

hr {
    margin-bottom: 1rem;
    border: none;
    width: 100%;
    height: 0.5px;
    color: var(--gray-accent-color); /* old IE */
    background-color: var(--gray-accent-color); /* Modern Browsers */
}

blockquote {
    quotes: '\201C''\201D''\2018''\2019';
    border-left: 10px solid #ccc;
    padding: 0.5em 10px;
}

blockquote:before {
    color: #ccc;
    content: open-quote;
    font-size: 4em;
    line-height: 0.1em;
    margin-right: 0.25em;
    vertical-align: -0.4em;
}

blockquote p {
    display: inline;
}

p {
    margin-bottom: 0.5rem;
}

p:last-child {
    margin-bottom: 0;
}

.center {
    text-align: center;
}

.sm {
    margin-top: -5px;
    /* font-size: 90%; */
}

.landing-trademark {
    margin-top: 16px;
    font-size: 80%;
    display: inline-block;
    vertical-align: text-top;
}

.clear {
    clear: both;
}

/* Utilities */
.containerfull {
    margin: 0;
    flex: 1; /* so it takes up all middle space after header and before footer */
    flex-direction: column;
    display: flex;
    /*min-height: calc(100vh - 118px); 55 for header, 63 for footer */
}

.containermiddle {
    /* width: 100%; */
    /* min-height: calc(100vh - 153px); */
    padding: 30px;
    margin: 0px 30px 30px;
    flex: 1; /* so it takes up all middle space in containerfull, which also is flex: 1 */
    background: white;
    box-shadow: 0px 10px 20px #4e4e4e1e;
    /* border-top: 3px solid var(--gray-accent-color); */
    position: relative;
}

.footer {
    padding: 20px 30px;
    font-size: 0.85rem;
    background: var(--gray-accent-color);
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: space-between;
    border-bottom: 3px solid #bbb;
}

.footer a {
    color: unset;
    text-decoration: underline;
    font-weight: normal;
}

#logo-footer {
    width: 28px;
    height: 25px;
}

.footer a.logolink {
    text-decoration: none;
}

/* Text Styles*/
.x-large {
    font-size: 4rem;
    line-height: 4rem;
    margin-bottom: 1rem !important;
}

.large {
    font-size: 2.1rem;
    line-height: 2.1rem;
    margin-bottom: 1rem;
}

.intro {
    font-size: 1.5rem;
    line-height: 2rem;
    font-weight: 500;
    width: 80%;
    color: var(--darker-color);
}

.lead {
    font-size: 1.3rem;
    font-weight: 300;
    line-height: 1.5rem;
    margin-bottom: 1rem;
}

.text-center {
    text-align: center;
}

.text-primary {
    color: var(--primary-color);
}

.text-primary-medium {
    color: var(--medium-primary-color);
}

.text-secondary {
    color: var(--secondary-color);
}

.text-dark {
    color: var(--dark-color);
}

.text-white {
    color: white;
}

.italicsmsg {
    font-style: italic;
    font-weight: bold;
    color: var(--muted-color);
    padding-top: 0.5rem;
    line-height: 1.4rem;
}

.allcaps {
    text-transform: uppercase;
    font-weight: bold;
}

.small {
    font-size: 0.8rem;
    line-height: 1rem;
}

.strong {
    font-weight: bold;
}

/* Padding */
.p {
    padding: 0.5rem;
}
.p-1 {
    padding: 1rem;
}
.p-2 {
    padding: 2rem;
}
.p-3 {
    padding: 3rem;
}
.py {
    padding: 0.5rem 0;
}
.py-1 {
    padding: 1rem 0;
}
.py-2 {
    padding: 2rem 0;
}
.py-3 {
    padding: 3rem 0;
}
.pb {
    padding: 0 0 0.5rem 0;
}
.pb-1 {
    padding: 0 0 1rem 0;
}
.pb-2 {
    padding: 0 0 2rem 0;
}
.pb-3 {
    padding: 0 0 3rem 0;
}
.pt-0 {
    padding-top: 0;
}
.p-0 {
    padding: 0;
}
.pt {
    padding: 0.5rem 0 0 0;
}
.pt-1 {
    padding: 1rem 0 0 0;
}
.pt-2 {
    padding: 2rem 0 0 0;
}
.pt-3 {
    padding: 3rem 0 0 0;
}

/* Margin */

.m {
    margin: 0 0.4rem;
}

.mll {
    margin-left: 0.1rem;
}

.ml {
    margin-left: 0.5rem;
}

.mr {
    margin-right: 0.4rem;
}

.mr-0 {
    margin-right: 0 !important;
}

.mrr {
    margin-right: 0.5rem;
}

.mr-1 {
    margin-right: 0.8rem;
}

.m {
    margin: 0.5rem;
}
.m-1 {
    margin: 1rem;
}
.m-2 {
    margin: 2rem;
}
.m-3 {
    margin: 3rem;
}
.my {
    margin: 0.5rem 0;
}
.my-1 {
    margin: 1rem 0;
}
.my-2 {
    margin: 2rem 0;
}
.my-3 {
    margin: 3rem 0;
}

.mt {
    margin-top: 0.5rem;
}

.mtq {
    margin-top: 0.25rem;
}

.mt-1 {
    margin-top: 1rem;
}

.mt-1p1 {
    margin-top: 1.1rem;
}

.mt-2 {
    margin-top: 2rem;
}
.mt-3 {
    margin-top: 3rem;
}

.mb {
    margin-bottom: 0.5rem;
}

.mbq {
    margin-bottom: 0.25rem;
}

.m-0 {
    margin: 0;
}

.mb-0 {
    margin-bottom: 0;
}

.mb-1 {
    margin-bottom: 1rem;
}
.mb-2 {
    margin-bottom: 2rem;
}
.mb-3 {
    margin-bottom: 3rem;
}

.op-8 {
    opacity: 80%;
}

.fr {
    float: right;
}

.fl {
    float: left;
}

.block {
    display: block;
}

.inlineblock {
    display: inline-block;
}

.inlineblock-btmpad {
    display: inline-block;
    padding-bottom: 0.5rem;
}

.inlineblock-btmpad:last-of-type {
    padding-bottom: 0;
}

.btn {
    display: inline-block;
    background: var(--light-color);
    color: var(--button-font-color);
    padding: 0.2rem 0.9rem;
    font-size: 0.9rem;
    font-weight: normal;
    font-family: 'Montserrat', sans-serif;
    line-height: 24px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 0.5rem;
    transition: opacity 0.2s ease-in;
    outline: none;
    height: fit-content;
    width: fit-content;
}

.btn-icon {
    display: inline-block;
    background: none;
    color: #333;
    font-size: 1.1rem;
    border: none;
    cursor: pointer;
    outline: none;
}

button.round {
    border-radius: 16px;
    padding: 7px 8px;
    line-height: 0.9rem;
}

button.favorite {
    font-size: 0.8rem;
    color: var(--danger-color);
    border: 2px solid var(--danger-color);
    border-radius: 16px;
    padding: 5px 6px 4px 6px;
}

button.wishlist {
    font-size: 0.85rem;
    color: var(--warning-color);
    border: 2px solid var(--warning-color);
    border-radius: 16px;
    padding: 4px 4px 3px 4px;
}

.btn-large {
    font-size: 1.4rem;
    line-height: 2rem;
    padding: 0.4rem 1.4rem;
    margin-right: 1rem;
}

.btn-small {
    padding: 0 0.5rem;
    font-size: 0.8rem;
    line-height: 1.5rem;
    margin-right: 0.5rem;
    border-radius: 6px;
}

.badge {
    font-size: 0.8rem;
    padding: 0.1rem;
    text-align: center;
    margin: 0.3rem;
    background: var(--light-color);
    color: #333;
}

.alert-container {
    width: 100%;
    position: fixed;
    top: 43px;
    left: 0;
    z-index: 100;
    pointer-events: none;
    /* allow clicks to go through container to potential nav links below */
}

.alert {
    padding: 10px 20px;
    margin: 0 auto 20px auto;
    opacity: 0.9;
    background: var(--primary-color);
    color: #fff;
    border-radius: 6px;
    max-width: 600px;
    pointer-events: initial;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.alert span {
    justify-self: flex-start;
    align-self: center;
}

.alert .fas {
    /* fontawesome icon */
    font-size: 1.5rem;
    justify-self: flex-end;
    align-self: center;
}

.alert .fas:hover {
    cursor: pointer;
}

.btn-primary,
.bg-primary,
.badge-primary,
.alert-primary {
    background: var(--primary-color);
    color: #fff;
}

.btn-highlight,
.bg-highlight,
.badge-highlight,
.alert-highlight {
    background: var(--highlight-color);
    color: #fff;
}

.btn-secondary,
.bg-secondary,
.badge-secondary,
.alert-secondary {
    background: var(--secondary-color);
    color: #fff;
}

.bg-light {
    background: var(--bg-color);
    color: #333;
}

.btn-light,
.badge-light,
.alert-light {
    background: var(--light-color);
    color: var(--button-font-color);
}

.btn-secondary-light,
.badge-secondary-light,
.alert-secondary-light {
    background: var(--light-secondary-color);
    color: var(--secondary-color);
}

.btn-muted {
    background: none;
    border: 1px solid var(--dark-color);
    color: var(--dark-color);
    cursor: default;
    transition: none;
}

.btn-muted:hover {
    background: none;
    cursor: default;
    transition: none;
    opacity: 1 !important;
}

.btn-dark,
.bg-dark,
.badge-dark,
.alert-dark {
    background: var(--dark-color);
    color: #fff;
}

.btn-danger,
.bg-danger,
.badge-danger,
.alert-danger {
    background: var(--danger-color);
    color: #fff;
}

.btn-success,
.bg-success,
.badge-success,
.alert-success {
    background: var(--success-color);
    color: #fff;
}

.btn-white,
.bg-white,
.badge-white,
.alert-white {
    background: #fff;
    color: var(--dark-color);
    border: #ccc solid 1px;
}

.btn:hover {
    opacity: 0.8;
}

.bg-light,
.badge-light {
    border: #ccc solid 1px;
}

/* Used for profile pics */
.round-img {
    border-radius: 50%;
    width: 150px;
    height: 150px;
    object-fit: cover;
}

.round-img-sml {
    border-radius: 50%;
    width: 46px;
    height: 46px;
    object-fit: cover;
}

.round-img-med {
    border-radius: 50%;
    width: 200px;
    height: 200px;
    object-fit: cover;
}

.round-img-lrg {
    border-radius: 50%;
    width: 300px;
    height: 300px;
    object-fit: cover;
}

.line {
    height: 1px;
    background: #ccc;
    margin: 1.5rem 0;
}

/* Search component */
.search-wrapper {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: auto min-content;
    padding-bottom: 1rem;
}

.searchbar-wrapper {
    border: 1px solid #ccc;
    display: grid;
    grid-gap: 10px;
    grid-template-columns: min-content auto min-content;
    padding-right: 10px;
}

.search-icon {
    display: grid;
    align-items: center;
    margin-left: 10px;
}

.searchbar {
    display: block;
    outline: none;
    background: #fff;
    opacity: 0.8;
    width: 100%;
    padding: 0.4rem 0.4rem 0.4rem 0;
    font-size: 1.2rem;
    font: inherit;
    border: none;
    color: var(--darker-color);
}

.searchbar-clear {
    align-self: center;
    justify-self: end;
}

/* Multi-select component */
.multiselect-group {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
    padding: 10px 0 0;
}

.multiselect-group div {
    padding: 10px;
    border: 1px solid var(--gray-accent-color);
    border-radius: 10px;
}

.multiselect-group div:hover,
.multiselect-group label,
.multiselect-group input {
    cursor: pointer;
}

.multiselect-group input {
    margin-right: 10px;
}

.ms-checked {
    background: var(--very-light-color);
    border-color: var(--light-color);
}

.ms-unchecked {
    background: none;
}

/* Forms */

.form {
    width: 100%;
}

.form .form-group {
    padding: 0.5rem 0;
}

.form-header {
    display: block;
    margin-top: 0.3rem;
    margin-bottom: 0.25rem;
    font-weight: bold;
    text-align: left;
}

/* Used for small text under fields */
.form-text {
    display: block;
    margin-top: 0.3rem;
    color: var(--muted-color);
    font-weight: bold;
    font-size: 0.9rem;
    line-height: 1rem;
    text-align: left;
}

.form input[type='text'],
.form input[type='email'],
.form input[type='password'],
.form input[type='date'],
.form input[type='file'],
.form select,
.form textarea {
    display: block;
    background: #fff;
    width: 100%;
    padding: 0.4rem;
    font-size: 1.2rem;
    border: 1px solid #ccc;
    font: inherit;
    font-weight: normal;
    color: var(--darker-color);
}

.form input[type='submit'],
button {
    font-family: 'Montserrat', sans-serif;
    font-size: 0.9rem;
    font-weight: inherit;
}

.form .social-input {
    display: flex;
}

.form .social-input i {
    padding: 0.5rem;
    width: 4rem;
}

.form .social-input i.fa-twitter {
    color: #38a1f3;
}
.form .social-input i.fa-facebook {
    color: #3b5998;
}
.form .social-input i.fa-instagram {
    color: #3f729b;
}
.form .social-input i.fa-youtube {
    color: #c4302b;
}
.form .social-input i.fa-linkedin {
    color: #0077b5;
}

/* to put input and upload button on same line */
.upload-actions {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr auto;
    grid-gap: 20px;
    align-items: center;
}

.upload-box {
    margin: 0 auto;
    display: flex;
    width: 50%;
    flex-flow: column;
    align-items: center;
    border: 1px solid var(--gray-accent-color);
    padding: 30px;
}

.row-split-two {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 30px;
}

/* autocomplete stuff */
.no-suggestions {
    color: var(--muted-color);
    padding: 0.5rem;
}

.suggestions {
    background: white;
    border: 1px solid #999;
    border-top-width: 0;
    list-style: none;
    margin-top: 0;
    max-height: 143px;
    overflow-y: auto;
    padding-left: 0;
    width: 100%;
    position: absolute;
    z-index: 1000;
}

.suggestions li {
    padding: 5px;
}

.suggestion-active,
.suggestions li:hover {
    background-color: #eee;
    cursor: pointer;
    font-weight: 700;
}

.suggestions li:not(:last-of-type) {
    border-bottom: 1px solid #999;
}

.suggestions li span {
    display: block;
}

.suggestions li span.subtext {
    font-size: 0.8rem;
    color: var(--muted-color);
    line-height: 0.85rem;
    margin-bottom: 5px;
}

.table th,
.table td {
    padding: 1rem;
    text-align: left;
}

.table th {
    background: var(--light-color);
}

header {
    position: sticky;
    z-index: 10;
    height: fit-content;
    width: 100%;
    top: 0;
    margin-bottom: 30px;
}
/* admin stuff */

.admin-form-footer {
    display: grid;
    grid-template-columns: 1fr auto;
    position: sticky;
    bottom: 0px;
    background: #fff;
    border-top: 1px solid rgb(189, 189, 189);
    margin-bottom: -20px;
}

.admin-dashboard-boxes {
    display: grid;
    grid-template-columns: repeat(
        auto-fit,
        minmax(100px, 1fr)
    ); /* 1fr 1fr 1fr 1fr 1fr; */

    grid-gap: 30px;
}

.admin-member-dashboard-boxes {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    grid-gap: 30px;
}

.admin-dashboard-box-small {
    background: var(--light-cool-color);
    /* color: white; */
    font-size: 1.8rem;
    padding: 30px;
    align-content: center;
    justify-content: center;
    text-align: center;
    display: grid;
    grid-auto-flow: row;
}

.admin-members-boxes {
    display: grid;
    /* grid-template-columns: 1fr 1fr 1fr 1fr; */
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-gap: 30px;
}

.admin-members-box {
    padding: 20px;
    align-content: center;
    justify-content: center;
    display: grid;
    grid-auto-flow: row;
    border: 1px solid rgb(189, 189, 189);
}

.admin-members-box img {
    justify-self: center;
    margin-bottom: 1rem;
}

.admin-members-box p:first-of-type {
    justify-self: center;
    font-size: 1.3rem;
}

/* Navbar */
.navbar {
    display: flex;
    justify-content: space-between;
    border: none;
    align-items: center;
    height: fit-content;
    hyphens: auto;
    padding: 10px 20px;
    background: white;
    box-shadow: 0px 0px 27px 0px #4e4e4e24;
}

.navbar .title a {
    font-family: 'Pacifico', sans-serif;
    letter-spacing: 0;
    margin-bottom: 0;
    font-weight: bold;
    font-size: 1.4rem;
    color: var(--highlight-color);
}

.navbar .title a:hover {
    color: var(--medium-highlight-color);
}

.logo-nav {
    width: 38px;
    height: 34px;
}

.navbar a img {
    margin-right: 6px;
}

.navbar a.selected {
    font-weight: 700;
}

/* used to render shadow under main navbar on scrolling */
.navshadow {
    box-shadow: 0px 2px 4px #4e4e4e50;
    width: 100%;
    /* height: fit-content; */
    border-radius: 30px;
    transition: box-shadow 0.5s ease-in-out;
}

.navbar h1 {
    font-size: 1.5em;
}

.navbar ul {
    display: flex;
}

.navbar a {
    color: var(--primary-color);
    padding: 0.45rem;
    margin: 0 0.25rem;
    font-family: 'Montserrat', sans-serif;
    font-weight: normal;
}

.navbar a:hover {
    color: var(--dark-color);
}

.navbar .welcome span {
    margin-right: 0.6rem;
}

/* admin nav bar */
.tabbar {
    display: flex;
    justify-content: space-between;
    border: none;
    align-items: center;
    padding: 10px 0 0;
    height: fit-content;
    hyphens: auto;
    font-size: 105%;
}

.tabbar ul {
    display: flex;
    border-bottom: 1px solid var(--gray-accent-color);
    width: 100%;
}

.tabbar li {
    margin-right: 36px;
    padding: 16px 0;
    /* background: var(--very-light-color); */
}

.tabbar li a {
    padding: 8px 0;
    margin: 0;
    color: var(--medium-primary-color);
    font-weight: normal;
    /* border: 1px solid var(--gray-accent-color); */
    border-bottom: 0;
}

.tabbar li a.selected {
    font-weight: 700;
    color: var(--primary-color);
    border-bottom: 3px solid var(--primary-color);
    padding-bottom: 14px;
}

.tabbar a:hover {
    color: var(--medium-primary-color);
}

.topnotification {
    display: flex;
    align-content: center;
    justify-content: center;
    text-align: center;
    padding: 10px 30px;
    height: fit-content;
    border-bottom: 1px solid #f5cfff;
    background: #e4fdff;
    background: linear-gradient(-90deg, #d7e2ff, #f5cfff);
    z-index: 11;
    /*linear-gradient(90deg, #e4fdff, #caecff); /* #e4fff6 #c4e2d6 */
}

.topnotification div {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    position: relative;
    height: 100%;
    z-index: 2;
    align-items: center;
}

.shadow {
    box-shadow: 0px 10px 20px #4e4e4e1e;
}

/* Landing Page */
.landing {
    background: var(--light-primary-color) url('./img/landing.jpg') no-repeat
        center center/cover;
    margin: 0 30px 30px;
    /* width: 100%; */
    /* overflow: scroll; */
    flex: 1; /* take up all available height */
    min-height: calc(100vh - 136px);
    display: grid;
    align-items: center;
    justify-content: center;
}

#landingabout {
    display: grid;
    grid-auto-flow: column;
    grid-gap: 0;
    margin: 0 30px 30px;
    background: #dce2f4;
    background: linear-gradient(122deg, #dce6ff, #dee6ff);
}

#landingabout h1 {
    font-size: 2.7rem;
    color: var(--highlight-color);
    font-family: unset;
    letter-spacing: unset;
    font-weight: normal;
}

#landingabout h3 {
    font-weight: normal;
    line-height: 1.8rem;
}

#landingabout img {
    width: 250px;
    height: 100%;
    object-fit: cover;
}

#landingabout div {
    padding: 30px;
}

/* Overlay */
#landing-overlay-main {
    background-color: #ffffff73;
    /* margin: 20px 60px; */
    /* max-height: calc(100vh - 160px); */
    padding: 30px;
    /* border-radius: 20px; */
    /* box-shadow: 0px 1px 4px #5c3a3a56; */
}

.landing-overlay {
    background-color: #ffffff9d;
    margin: 20px 60px;
    /* max-height: calc(100vh - 160px); */
    padding: 40px;
    border-radius: 20px;
    box-shadow: 0px 1px 4px #5c3a3a56;
    /* overflow: scroll; */
}

.landing-inner {
    color: var(--dark-color);
    padding: 0 20px;
    margin: auto;
    display: grid;
    grid-template-columns: auto 400px;
    grid-gap: 50px;
    align-items: center;
    /* text-align: center; */
}

.landing-form {
    color: var(--dark-color);
    /* width: 80%; */
    margin: auto;
    display: grid;
    grid-template-columns: auto;
    grid-gap: 0;
    align-items: center;
    text-align: center;
}

.landing-img {
    width: 400px;
    height: 400px;
    object-fit: cover;
    border-radius: 50%;
    /* border: 4px solid #ffffff38; */
}

.imgsliderwrapper {
    display: grid;
    align-items: center;
    text-align: center;
}

.imgsliderbtn {
    padding: 5px 10px;
    margin: 5px 0 0;
    cursor: pointer;
    color: white;
    background: var(--dark-color);
    border: 0;
    border-radius: 4px;
    outline: none;
}

.landing-buttons {
    margin-top: 2rem;
}

/* Journey Page */
.datecolumns {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: auto auto auto;
}

.journeycontainer {
    margin: 0 auto;
    max-width: 900px;
    display: grid;
    grid-template-columns: auto 30px 1fr;
    column-gap: 20px;
    justify-items: center;
}

.journeyyear {
    display: grid;
    grid-template-columns: auto 30px;
    width: 100%;
    position: sticky;
    top: 202px;
    height: 45px;
    /* z-index: 2; */
    justify-self: flex-start;
    align-items: center;
    background: white;
}

.journeyyear::after {
    content: '';
    position: absolute;
    width: 30px;
    height: 30px;
    right: -52px;
    background: var(--secondary-color);
    border: 2px solid white;
    top: 15px;
    border-radius: 50%;
    z-index: 3;
  }

.journeyyear > h1 {
    background: white;
    font-family: 'Bebas Neue', sans-serif;
    font-size: 40px;
    line-height: 40px;
    letter-spacing: 0;
    margin-top: 12px;
}

.journeyline, .journeylinelastwithdate, .journeylinefirst {
    /* border-right: 2px solid var(--muted-color); */
    border-right-width: 4px;
    border-right-style: solid;
    border-image-source: linear-gradient(90deg,var(--dark-color), var(--darker-color));
    border-image-slice: 14;
}

.journeylinelast {
    border-right: none;
}

.journeylinefirst {
    border-right: none;
}

.journeylinelastwithdate {
    height: 30px;
}

.journeydate {
    background: white;
}

/* The circles on the timeline */
.journeydate::after {
    content: '';
    position: absolute;
    width: 18px;
    height: 18px;
    right: -46px;
    background-color: var(--medium-secondary-color);
    border: 2px solid white;
    top: 15px;
    border-radius: 50%;
    z-index: 3;
}

.journeytimeline {
    position: sticky;
    top: 247px;
    width: 100%;
    background: white;
}

.journeytimelinelast {
    align-self: start;
}

.journeytimeline h2 {
    background: white;
    color: var(--muted-color);
    font-family: 'Bebas Neue', sans-serif;
    font-size: 30px;
    line-height: 30px;
    letter-spacing: 0;
    margin-top: 12px;
}

.journeyentrywrapper {
    display: grid;
    grid-template-rows: 1fr;
    gap: 10px;
    justify-items: center;
    width: 100%;
    border-radius: 10px;
    margin: 15px 0;
    /* background: var(--light-warm-color); */
    padding: 10px;
    background: linear-gradient(-330deg,#f2e6f1, #e7f4fd);
    
}

.journeyentrycols {
    display: grid;
    grid-template-columns: 250px minmax(150px, 750px);
    border-radius: 10px;
}

.journeyentrycols-feat {
    display: grid;
    grid-template-columns: 350px minmax(150px, 750px);
    border-radius: 10px;
}

.journeypic {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
}

.journeytextcontent {
    display: grid;
    align-content: start;
    justify-items: start;
    /* background: rgba(255, 255, 255, 0.674); */
    padding: 30px;
}

/* Products Page */

.data-container {
    display: grid;
    grid-template-columns: minmax(100px, 260px) auto;
    grid-gap: 20px;
}

.sticky-header-wrapper {
    position: sticky;
    background: white;
    padding-top: 30px;
    margin-top: -30px;
    top: 69px;
    z-index: 9;
}

.border-bottom {
    border-bottom: 1px solid var(--gray-accent-color);
}

.data-search {
    padding-right: 20px;
    border-right: 1px solid var(--very-light-gray-accent-color);
    /* position: sticky;
    top: 220px;
    height: calc(100vh - 240px);
    align-self: start;
    overflow: scroll; */
}

/* .data-search-top {
    background: white;
    position: sticky;
    top: 0;
    align-self: start;
    overflow: scroll;
    z-index: 10;
} */

.data-search .item-tag {
    white-space: break-spaces;
}

.data-search h2,
h2.products_start {
    font-family: 'Bebas Neue', sans-serif;
    font-size: 1.8rem;
    letter-spacing: 1px;
    text-transform: uppercase;
    /* margin-bottom: 0; */
}

h2.products_start {
    margin-bottom: 0.5rem;
}

.products-wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-gap: 30px;
}

.product {
    display: flex;
    flex-direction: column;
    padding: 20px 20px 8px;
    border: 1px solid var(--gray-accent-color);
}

.product img {
    max-width: 300px;
    max-height: 300px;
    object-fit: contain;
    align-self: center;
    justify-self: center;
    margin-bottom: 20px;
}

.product p {
    line-height: 1rem;
    font-size: 0.85rem;
}

.product h2 {
    font-size: 1.3rem;
    line-height: 1.3rem;
}

.product a.img {
    text-align: center;
}

.product-img {
    max-width: 400px;
    max-height: 400px;
    object-fit: contain;
}

.product-comment {
    border: 1px solid var(--gray-accent-color);
    padding: 10px 15px;
    margin: 15px 0;
    display: grid;
    grid-template-columns: 46px calc(100% - 70px);
    grid-gap: 20px;
    align-content: center;
}

.productpurchaselinks {
    /* display: flex;
    grid-gap: 20px; */

    flex-wrap: wrap;
    display: flex;
    grid-gap: 0 10px;

    /* display: grid;
    grid-auto-flow: column;
    grid-template-columns: repeat(auto-fit, minmax(150px, 25%)); */
}

.productpurchaselinks > div {
    background: var(--very-light-primary-color);
    border: 1px solid var(--button-font-color);
    border-radius: 5px;
    margin-bottom: 0.5rem;

    display: grid;
    grid-auto-flow: column;
    grid-gap: 20px;
    justify-content: left;
    align-items: center;
}

.productpurchaselinks div > a {
    display: grid;
    grid-auto-flow: column;
    grid-gap: 10px;
    padding: 15px;
    align-items: center;
    border-radius: 5px;
}

.productpurchaselinks div > a:hover {
    color: var(--medium-primary-color);
}

.productpurchaselinks div a div {
    display: grid;
    grid-auto-flow: row;
    /* grid-gap: 20px; */
    align-items: center;
}

/* details page */
.product-comment > div:first-child {
    align-self: top;
}

.product-comment:last-child {
    margin-bottom: 0;
}

.product-page-header {
    display: grid;
    grid-template-columns: 400px 1fr;
    grid-gap: 30px;
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid var(--gray-accent-color);
}

.product-page-header ul,
.product-notes ul {
    list-style: disc;
}

.product-page-header ul,
.product-notes ul,
ol {
    padding-bottom: 0.7rem;
    margin-left: 18px;
}

.product-notes {
    background: var(--very-light-gray-accent-color);
    padding: 20px;
}

.directions-list ul {
    list-style: disc;
    margin-left: 18px;
}

/* ingredient form */

.twocol {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 0 30px;
}

.ingredient-property-checklist {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 0 30px;
}

.ingredient-property-checklist > .form-group {
    margin: 0.5rem 0;
}

/* Ingredients Page */

.ingredient_row_wrapper {
    border: 1px solid var(--gray-accent-color);
    padding: 20px 20px 0 20px;
}

.ingredient-row-split {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 30px;
    padding: 0 0 10px 0;
}

.rel {
    position: relative;
}

.ingredient,
.ingredient-header {
    display: grid;
    grid-template-columns: 4fr 1fr 4fr 1fr;
    align-items: center;
    grid-gap: 30px;
    padding: 1rem 0;
    line-height: 1.8;
    border-bottom: 1px solid #b6b6c3;
}

.ingredient-header {
    padding-top: 0;
}

.ingredient-header,
.ingredient-list-header-cell {
    text-transform: uppercase;
    font-weight: bold;
    color: var(--dark-color);
    padding-bottom: 0;
    font-size: 0.9rem;
}

.ingredient-list-items-wrapper > div {
    padding: 2px 10px 2px 0;
    height: 100%;
    min-height: 35px;
    display: grid;
    justify-items: start;
    align-items: center;
    border-bottom: 1px solid #b6b6c3;
}

.ingredient-list-items-wrapper > div > * {
    width: 100%;
}

.ingredient-list-items-wrapper > div.full-width {
    grid-column: 1/-1;
}

.ingredient-list-items-wrapper {
    display: grid;
    grid-template-columns: auto max-content auto auto;
    /* grid-template-rows: auto; */
    align-items: center;
    grid-row-gap: 1px;
    margin: 1rem 0;
    margin-bottom: 1.5rem;
    /* overflow: scroll; commented out b/c autocomplete suggestions list gets clipped */
    hyphens: auto;
    overflow: scroll;
}

.ingredient h3 {
    line-height: 1.2rem;
}

.ingredient > div:last-child {
    justify-self: end;
}

.ingredient-row-adv {
    border: 1px solid var(--gray-accent-color);
    background: #f8f8f8;
    padding: 10px;
    margin-bottom: 10px;
    margin-top: -10px;
    display: block;
}

.parse-ing-adv {
    border: 1px solid var(--gray-accent-color);
    background: #f8f8f8;
    padding: 10px;
    margin-bottom: 20px;
    display: block;
}

.ingredient-row-adv:hover,
.parse-ing-adv:hover {
    cursor: pointer;
}

.ingredient-row-adv h3 {
    margin-bottom: 10px;
    /* text-transform: uppercase; */
}

/* ingredient rating levels */
.rating-poor {
    color: var(--danger-color);
}

.rating-average {
    color: var(--warning-color);
}

.rating-good {
    color: var(--success-color);
}

.rating-great {
    color: rgb(25, 149, 118);
}

.catchy {
    color: var(--catchy-color);
}

.ing-source {
    font-size: 0.8rem;
    color: var(--muted-color);
}

.ing-source a {
    color: var(--muted-color);
    word-break: break-all;
}

.synonym {
    display: inline-block;
    padding: 2px 8px;
    margin: 0 7px 7px 0;
    background: var(--light-gray-accent-color);
    border-radius: 5px;
    font-size: 0.8rem;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    text-transform: uppercase;
    white-space: nowrap;
}

.synonym:last-child {
    margin-right: 0;
}

/* Profiles Page */
.profile {
    display: grid;
    grid-template-columns: 1fr 3fr 3fr;
    align-items: center;
    grid-gap: 30px;
    padding: 1rem;
    line-height: 1.8;
    /* margin-bottom: 1rem; */
    border-bottom: 1px solid var(--gray-accent-color);
}

/* Profile Page */
.profile-grid {
    display: grid;
    grid-template-areas:
        'top top'
        'hair1 hair2';
    grid-gap: 1rem;
    grid-template-columns: calc(50% - 0.5rem) calc(50% - 0.5rem);
}

.profile-top {
    grid-area: top;
    display: grid;
    grid-template-columns: 200px auto;
    gap: 30px;
    padding: 30px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    background: #eaf2f3;
    /* margin-top: 100px; */
}

.profile-top img {
    width: 200px;
    height: 200px;
    object-fit: cover;
    border-radius: 50%;
}

.profile-top .icons a {
    color: var(--primary-color);
    margin: 0 0.3rem;
}

.profile-top .icons a:hover {
    color: var(--dark-color);
}

.profile-content {
    display: grid;
    text-align: left;
    justify-items: start;
}

.profile-hair1 {
    grid-area: hair1;
}

.profile-hair2 {
    grid-area: hair2;
}

.profile-hair1 h2,
.profile-hair2 h2 {
    margin-bottom: 1rem;
}

.profile-hair1 > div {
    padding-bottom: 10px;
    border-bottom: #ccc 1px dotted;
}

.profile-hair1 > div:last-child,
.profile-hair2 > div:last-child {
    border: 0;
}

.profile-hair1 p,
.profile-hair2 p {
    margin: 0.5rem 0;
}

.hairtypes-wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(70px, 100px));
    grid-gap: 20px;
    justify-items: center;
    padding: 20px 0;
}

.hairtypes-wrapper img {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    padding: 10px;
    /* border: 1px solid var(--dark-color); */
    background: var(--light-warm-color);
}

/* company styles */

.company-header, .company {
    display: grid;
    grid-template-columns: 2fr 1fr 4fr 1fr;
    align-items: center;
    grid-gap: 30px;
    padding: 1rem 0;
    line-height: 1.8;
    border-bottom: 1px solid #b6b6c3;
}

.company-header {
    padding-top: 0;
    text-transform: uppercase;
    font-weight: bold;
    color: var(--dark-color);
    padding-bottom: 0;
    font-size: 0.9rem;
}

.company>div:last-child {
    justify-self: end;
}

/* Regimen styles */

.regimen {
    display: grid;
    grid-template-columns: 250px auto;
    grid-gap: 30px;
    background: var(--very-light-cool-color);
}

.regimen-pg-tile {
    display: grid;
    grid-template-columns: 250px auto;
    grid-gap: 10px;
    /* border-top: 1px solid #9ab2b2; */
    border-radius: 4px;
    background: var(--very-light-cool-color);
}

.regimen-header {
    display: grid;
    grid-template-rows: 1fr;
    grid-gap: 10px;
    justify-items: center;
}

.regimen-creator {
    display: grid;
    grid-auto-flow: column;
    grid-gap: 6px;
    align-items: center;
    justify-items: center;
    justify-content: left;
}

.regimen-creator img {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    object-fit: cover;
}

.regimen-img {
    width: 250px;
    height: 250px;
    /* border-radius: 8px; */
    object-fit: cover;
}

.regimen-pg-tile .regimen-img {
    width: 100%;
    height: 100%;
    border-radius: 4px 0 0 4px;
    /* padding: 4px; */
}

.regimen-step-wrapper {
    /* background: #f8f8f8; */
    border: 1px solid var(--muted-color);
    padding: 20px;
    margin-bottom: 20px;
}

.regimen-step-wrapper:last-of-type {
    margin-bottom: 0;
}

/* for holding regimen tiles */
.regimens-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(600px, 1fr));
    grid-gap: 30px;
}

.regimens-wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-gap: 30px;
}

.regimen-tile {
    display: grid;
    align-content: start;
    background: var(--very-light-cool-color); /*var(--very-light-cool-color);*/
    border-radius: 8px;
}

.regimen-tile .header {
    display: grid;
    grid-template-rows: 1fr;
    justify-items: center;
    height: 300px;
}

.regimen-tile .header a {
    width: 100%;
}

.regimen-tile h3 a,
.regimen-pg-tile h3 a {
    font-weight: 500;
}

.regimen-tile-img {
    height: 300px;
    object-fit: cover;
    border-radius: 8px 8px 0 0;
}

.regimen-tile-bottom {
    padding: 20px;
}

.regimen-tile-bottom a,
.regimen-pg-tile a {
    color: var(--medium-highlight-color);
}

/* Posts Page: used for displaying products, ingredients, etc. */

.post-comment-count {
    background: var(--light-color);
    color: var(--primary-color);
    padding: 0.1rem 0.2rem;
    border-radius: 5px;
    font-size: 0.8rem;
}

.post-date,
.post-hairprops {
    color: var(--muted-color);
    font-size: 0.8rem;
    margin-bottom: 0.5rem;
}

.post-hairprops {
    font-weight: 500;
    font-size: 0.75rem;
    text-transform: uppercase;
}

.post-wrapper {
    border-top: #ccc solid 1px;
}

.regimen-steps ol {
    list-style: none;
    margin: 0 auto;
}

.regimen-steps ol li {
    display: grid;
    grid-template-columns: auto auto;
    justify-content: space-between;
    grid-gap: 20px;
    margin: 0 0 1rem 0;
    padding: 30px;
    position: relative;
    border: 1px solid var(--light-catchy-color);
    /* background: var(--very-light-gray-accent-color); */
}

.regimen-steps ol li img,
.regimen-steps ol li video {
    margin: -26px -26px -26px 0;
    border-radius: 0;
}

/* .regimen-steps ol li::before, */
.regimen-step-number {
    font-size: 1.5rem;
    font-weight: 500;
    text-align: center;
    color: var(--dark-color);
    --size: 50px;
    /* left: calc(-1 * var(--size) - 10px); */
    line-height: var(--size);
    width: var(--size);
    height: var(--size);
    border: 1px solid var(--dark-color);
    border-radius: 50%;
    text-align: center;
}

.regimen-step-content {
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 20px;
}

.regimen-step-products {
    display: flex;
    flex-direction: row;
    /* flex-wrap: wrap; */
    align-items: flex-start;
}

.rating {
    text-transform: uppercase;
    font-family: 'Open Sans', sans-serif;
    font-weight: bold;
    font-size: 0.85rem;
}

.rating-wrapper {
    display: inline-block;
    padding-bottom: 0.5rem;
}

.rating-hover {
    color: var(--bright-highlight-color);
    cursor: pointer;
}

.rating-filled {
    color: var(--medium-highlight-color);
}

.item-tag {
    border: 1px solid var(--muted-color);
    color: var(--muted-color);
    border-radius: 12px;
    display: inline-block;
    /* white-space: nowrap; */
    margin: 2px 5px 2px 0;
    padding: 3px 8px;
    font-size: 0.8rem;
    line-height: 0.8rem;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    width: auto !important;
}

.item-tag i {
    padding-left: 5px;
}

.tag-product-bool {
    border: 1px solid var(--dark-color);
    color: var(--dark-color);
    border-radius: 0;
    padding: 3px 5px;
    text-transform: uppercase;
}

.tag-product-bool i {
    padding: 0;
}

.tag-private {
    border: 1px solid var(--muted-color);
    color: var(--muted-color);
    border-radius: 0;
    padding: 3px 5px;
}

.tag-private i {
    font-size: 0.7rem;
    padding: 0;
}

.tag-error {
    color: var(--danger-color);
    border: 1px solid var(--danger-color);
}

.tag-warning {
    color: var(--warning-color);
    border: 1px solid var(--warning-color);
}

.tag-success {
    color: var(--success-color);
    border: 1px solid var(--success-color);
}

.tag-clickable:hover {
    cursor: pointer;
}

.tag-clickable a {
    color: inherit;
    font-weight: inherit;
    text-decoration: inherit;
}

.item-tag.tag-clickable.tag-default {
    color: var(--default-clickable-tag-color);
    border: 1px solid var(--secondary-color);
    background: var(--very-light-secondary-color);
}

.item-tag.tag-clickable.tag-success {
    background: var(--very-light-success-color);
}

.item-tag.tag-clickable.tag-error {
    background: var(--very-light-danger-color);
}

.item-tag.tag-clickable.tag-warning {
    background: var(--very-light-warning-color);
}

.tag-clickable:hover,
.item-tag.tag-clickable.tag-default:hover,
.item-tag.tag-clickable.tag-success:hover,
.item-tag.tag-clickable.tag-error:hover,
.item-tag.tag-clickable.tag-warning:hover {
    background: white;
}

.item-tag i.deletable:hover {
    cursor: pointer;
    color: var(--light-primary-color);
}

/* PAGINATION */
.pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}

.paginationItem {
    background: none;
    border: 2px solid var(--primary-color);
    color: var(--primary-color);
    font-weight: bold;
    padding: 10px 15px;
    border-radius: 50%;
    height: 45px;
    width: 45px;
    position: relative;
    margin: 0 5px;
    cursor: pointer;
}

.paginationItem span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.prev,
.next {
    background: #fff;
    border: none;
    padding: 10px;
    color: var(--primary-color);
    font-size: 2.3rem;
    font-weight: bold;
    /* box-shadow: 0 0 3px rgba(0, 0, 0, 0.4); */
    margin: 0 10px;
    cursor: pointer;
}

.paginationItem.dots {
    border: none;
    pointer-events: none;
    color: var(--muted-color);
    padding: 10px 0;
    margin: 0;
}

.paginationItem.active {
    background: var(--primary-color);
    border-color: var(--primary-color);
    color: #fff;
    pointer-events: none;
}

.prev.disabled,
.next.disabled {
    pointer-events: none;
    box-shadow: none;
    color: var(--muted-color);
}

/* Big Screen Styles */
@media (min-width: 1600px) {
    .products-wrapper {
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    }

    .product img {
        max-width: 240px;
    }
}

/* Mobile Styles */
@media (max-width: 700px) {
    .container {
        margin-top: 8rem;
    }

    .containerfull {
        margin: 0;
        /* min-height: unset; */
    }

    .containermiddle {
        margin: 10px 5px 5px;
    }

    .landing {
        margin: 10px 0 0;
        height: auto;
        grid-template-columns: 1fr; /* so form box is wide */
    }

    #landingabout h1 {
        font-size: 2.2rem;
        margin-bottom: 10px;
    }

    #landingabout {
        grid-auto-flow: row;
        padding: 30px;
        margin: 30px;
    }

    .landing-overlay {
        width: 95%;
        margin: 10px auto;
        max-height: none;
        padding: 20px;
        align-self: start;
    }

    .landing-inner {
        width: 100%;
        padding: 0;
        grid-template-columns: none;
        grid-template-rows: auto auto;
    }

    .landing-img {
        justify-self: center;
    }

    .hide-on-mobile {
        display: none;
    }

    h1 {
        font-size: 2rem;
        letter-spacing: 0;
    }

    /* Text Styles */
    .x-large {
        font-size: 3rem;
    }

    .large {
        font-size: 2rem;
    }

    .lead {
        font-size: 1rem;
        line-height: 1.2rem;
    }

    /* Navbar */
    .navbar {
        display: block;
        text-align: center;
        height: 80px;
        padding: 10px;
    }

    .navbar ul {
        text-align: center;
        justify-content: center;
        font-weight: normal;
    }

    .navbar a,
    .tabbar a {
        padding: 0;
        font-weight: normal;
        font-size: 0.8rem;
        margin: 0 2px;
    }

    .navbar .title a {
        margin: 0 auto;
    }

    .navbar .welcome {
        display: none;
    }

    .navbar h1 {
        display: flex;
    }

    .intro {
        width: 100%;
    }

    /* Ingredients page */
    .ingredient {
        grid-template-columns: 1fr;
        grid-gap: 0px;
    }

    /* .item-tag {
        white-space: normal;
    } */

    /* Ingredients/form page */
    .ingredient-property-checklist,
    .product-page-header {
        grid-template-columns: 1fr;
    }

    .ingredient-list-items-wrapper {
        grid-template-columns: auto auto auto auto;
    }

    /* Products page */
    .products-wrapper {
        grid-template-columns: 1fr;
        grid-gap: 20px;
    }

    /* Profiles Page */
    .profile {
        grid-template-columns: 1fr;
        text-align: center;
    }

    .profile ul {
        display: none;
    }

    /* Profile Page */

    .profile-top img,
    .profile img {
        width: 200px;
        height: 200px;
        margin: auto;
    }

    .profile-grid {
        grid-template-areas:
            'top'
            'hair1'
            'hair2';
        grid-template-columns: 1fr;
    }

    .dash-buttons a {
        display: block;
        width: 100%;
        margin-bottom: 0.2rem;
        letter-spacing: 0;
    }

    .regimen,
    .regimen-steps ol li,
    .company {
        display: grid;
        grid-template-columns: 1fr;
        background: none;
        /* grid-gap: none; */
    }

    .regimens-container {
        grid-template-columns: 1fr;
    }

    .regimen-pg-tile {
        grid-template-columns: 1fr;
        grid-gap: 0;
    }

    .admin-dashboard-boxes,
    .admin-member-dashboard-boxes,
    .admin-members-boxes {
        grid-template-columns: none;
        grid-auto-flow: row;
    }

    .upload-box {
        width: 100%;
    }

    .row-split-two {
        grid-template-columns: none;
        grid-gap: 1rem;
    }

    .data-container {
        grid-template-columns: none;
    }

    .data-search {
        padding-right: 0;
        border-right: 0;
        position: unset;
        height: auto;
    }

    .paginationItem {
        padding: 15px;
        height: auto;
        width: auto;
    }

    .journeyentrycols, .journeyentrycols-feat {
        grid-template-columns: none;
    }
}
